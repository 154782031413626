import React from 'react';
import { 
  styled
} from '@mui/material/styles';

import {
  Box,
	Typography,
  Card, 
  CardContent
} from '@mui/material';
import { Parallax } from 'react-parallax';

// Custom styled Card with Glassmorphism effect
const GlassCard = styled(Card)(({ theme }) => ({
  background: 'rgba(255, 255, 255, 0.1)', // Semi-transparent background
  backdropFilter: 'blur(8px)',           // Blurring effect
  borderRadius: '15px',                   // Rounded corners
  boxShadow: '0 4px 30px rgba(0, 0, 0, 0.4)', // Soft shadow for depth
  border: '1px solid rgba(255, 255, 255, 0.1)', // Subtle border
  maxWidth: '500px',
  color: '#000',
}));

export default function FrontPage() {
  return (
    <Parallax
      bgImage={require('../images/ReachingForFish.webp')}
      strength={400}
    >
      <Box sx={{
        height: 'calc(var(--vh, 1vh) * 100)',
        display: 'grid',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        color: 'white',
      }}>
        <GlassCard>
          <CardContent>
            <Typography variant="h3">Matthew Paul White</Typography>
            <Typography variant="h4">Solutions Architect | Bangkok</Typography>
          </CardContent>
        </GlassCard>
      </Box> 
    </Parallax>
  );
}