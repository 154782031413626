import React from 'react';
import { 
  createTheme
} from '@mui/material/styles';
import {
	Paper,
	Grid,
	Typography,
	IconButton,
  Tooltip
} from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

const theme = createTheme();

export default function MyAppBar() {
  const date = new Date();
  
  return (
    <div>
      <meta name="description" content="Footer which references key framework, cookie policy, ownership and useful links." />
      <Paper>
        <Grid 
          container 
          sx={{
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            display: 'grid',
            textAlign: 'center'
          }}
        >
          <Grid item xs={12}>
            <Tooltip title="LinkedIn">
              <IconButton 
                color="primary"
                href="https://www.linkedin.com/in/matthew-white-ba30a95a/" 
                target="_blank" 
                rel="noreferrer"
                aria-label="LinkedIn"
              >
                <LinkedInIcon/>
              </IconButton>
            </Tooltip>
            <Tooltip title="Credly">
              <IconButton 
                color="primary"
                href="https://www.credly.com/users/matthew-white.1230beac/badges#" 
                target="_blank" 
                rel="noreferrer"
                aria-label="Credly"
              >
                <WorkspacePremiumIcon/>
              </IconButton>
            </Tooltip>
            <Typography variant="body1">
              Handmade with <a href="https://mui.com/" target="_blank" rel="noreferrer">MUI</a>
            </Typography>
            <Typography variant="body1">
              This site collects no information on you at all
            </Typography>
            <Typography variant="body1">
              © MPWhite.co.uk | {date.getFullYear()}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}