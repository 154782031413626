import React from 'react';

import { 
  createTheme
} from '@mui/material/styles';
import { styled } from '@mui/system';
import {
  Box,
  Grid,
	Container,
	Typography,
  Paper,
  IconButton,
  Tooltip
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import Kainos_logo from '../images/Kainos_logo.webp';
import Vodafone_logo from '../images/Vodafone_logo.webp';
import Northrop_logo from '../images/Northrop_Grumman.webp';
import BT_logo from '../images/BT_logo.webp';
import ExperienceSection from '../sections/ExperienceSection.js';

const theme = createTheme();

const ExpAvatar = styled('img')({
  margin: 'auto',
  display: 'block',
  height: '6em',
  padding: theme.spacing(2)
});

export default function Experience() {
  const defaultHeight = '100vh';
  const [myHeight, setMyHeight] = React.useState(defaultHeight);

  const defaultGradiantHeight = '30vh';
  const [gradiantHeight, setGradiantHeight] = React.useState(defaultGradiantHeight);

  const defaultGradiantColor = 'linear-gradient(180deg, rgba(255,255,255,0), rgba(255,255,255,1))';
  const [gradiantColor, setGradiantColor] = React.useState(defaultGradiantColor);

  const [expanderValue, setExpanderValue] = React.useState(true);
  const expander = (status) => {
    if (status === true) {
      setMyHeight('auto');
      setGradiantHeight('auto');
      setGradiantColor('linear-gradient(180deg, rgba(255,255,255,0), rgba(255,255,255,0))');
      setExpanderValue(false);
    } else {
      setMyHeight(defaultHeight);
      setGradiantHeight(defaultGradiantHeight);
      setGradiantColor(defaultGradiantColor);
      setExpanderValue(true);
    }
  };
  
  const expandToggler = () => {
    if (expanderValue === true) {
      return (
        <Tooltip title="Expand">
          <ExpandMoreIcon aria-label="Expand" />
        </Tooltip>
      )
    } else {
      return (
        <Tooltip title="Reduce">
          <ExpandLessIcon aria-label="Reduce" />
        </Tooltip>
      )
    }
  };

  return (
    <div>
      <meta name="description" content="Experience section provides details on my work experiences." />
      <Box sx={{position: 'relative'}}>
        <Grid 
          container 
          alignItems="flex-end" 
          sx={{
            position: 'absolute',
            width: '100%',
            bottom: '0'
          }}
          style={{
            height: gradiantHeight, 
            backgroundImage: gradiantColor
          }} 
        >
          <Grid item key="toggler" xs={12} sx={{
            textAlign: 'center'
          }}>
            <IconButton sx={{
              marginBottom: theme.spacing(1)
            }} color="primary" aria-label="expand" onClick={() => expander(expanderValue)}>
              {expandToggler()}
            </IconButton>
          </Grid>
        </Grid>
          <Container 
          sx={{
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            overflow: 'hidden'
          }}
          style={{height: myHeight}} 
          maxWidth="lg" 
          id="Experience"
        >
          <Typography gutterBottom variant="h3" align="center">
            Experience
          </Typography>
          <Grid container spacing={2}>
            <Grid item key="mk" xs={12}>
              <Paper sx={{
                padding: theme.spacing(2),
                margin: 'auto'
              }}>
                <Grid container spacing={2}>
                  <Grid item key="Kainos" xs={12}>
                    <Typography gutterBottom variant="h4" align="center">
                      Technical Architect Consultant - Security Cleared | Kainos
                    </Typography>
                    <ExpAvatar 
                      alt="complex" 
                      src={Kainos_logo} 
                    />
                    <ExperienceSection 
                      title="Technical Lead and Architect, SC Cleared, HM Passport Office"
                      time="January 2023 - September 2024"
                      description={"Brought in as lead for the UK passport digitisation project which had suffered extensive team rotations and missed deadlines. Through streamlining requirements, securing buy-in from teams and leveraging of industry best practices, the project was delivered on time and to budget.\nRemoved use of bespoke environments by introducing centrally managed automation via CI/CD pipelines and Infrastructure as Code (IaC). Reducing deployments from 6 hours to 15 minutes, ephemeral environments preventing bottlenecks and making bug replication much simpler.\nProactively engaged the security team to address historic decisions and processes that were causing delays. Through understanding, we agreed on new ways of working which eliminated the weekly approval window for non-production environments completely unblocking the team.\nWinner of multiple awards from both HMPO and Kainos including ‘Outstanding Contribution’, ‘Customer Champion’ and ‘Positivity’ - highlighting effective delivery done in the right way."}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item key="mv" xs={12}>
              <Paper sx={{
                padding: theme.spacing(2),
                margin: 'auto'
              }}>
                <Grid container spacing={2}>
                  <Grid item key="LeadVodafone" xs={12}>
                    <Typography gutterBottom variant="h4" align="center">
                      Lead Solutions Architect | Vodafone Group
                    </Typography>
                    <ExpAvatar 
                      alt="complex" 
                      src={Vodafone_logo} 
                    />
                    <ExperienceSection 
                      title="Partner Content Collaborations"
                      time="April 2021 - December 2022"
                      description={"Led the standardization of partner integrations, which resulted in a 50% reduction in onboarding speed by harmonizing integration approaches and UX across platforms.\nEnhanced stakeholder and squad engagement by adopting the role of Scrum Master, focusing on customer deliverables, and facilitating the writing of use cases under precise epics.\nReduced internal complexity and streamlined future partner/device integrations by:\n\tMigration away from per partner/market customer IDs to a universal ID.\n\tLogically separated the frontends from the backend via APIs."}
                    />
                    <ExperienceSection 
                      title="Customer Onboarding Assistant"
                      time="April 2020 - March 2021"
                      description={"Gained approval for a customer onboarding RCS chatbot which, in trials increased engagement by 30% by designing and being the technical lead for the initial prototype.\nArchitected and brought to multiple markets a full RCS chatbot solution in <6 months, used to engage new/upgraded users to reduce churn, achieved by leveraging existing functionalities."}
                    />
                    <ExperienceSection 
                      title="Start Application and Website Sunset"
                      time="January 2020 - March 2020"
                      description={"Removed the need for any ongoing app maintenance for a preinstalled, non-removable app with admin privileges, saving ~£0.5 million in lifetime costs by researching and demoing having the app install an inert “hollow app” over itself.\nMigrated a monolith platform to a cloud-native serverless architecture, saving ~£1.5 million in lifetime costs by redesigning APIs for stateless transactions and implementation in AWS.\nRecognized with an award for outstanding contribution at the end of the project."}
                    />
                    <ExperienceSection 
                      title="Start Application and Website"
                      time="July 2017 - December 2019"
                      description={"Collaborated with commercial owners to investigate migrating the existing app user base of 23 million into a revenue stream, I provided technical analysis and managed a user focus group resulting in the decision to sunset the application.\nLead architect on the iOS app and maximized reused and delivery speed by wrapping the existing browser implementation within a webview to give a native iOS experience."}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item key="mv1" xs={12}>
              <Paper sx={{
                padding: theme.spacing(2),
                margin: 'auto'
              }}>
                <Grid container spacing={2}>
                  <Grid item key="VodafoneStart" xs={12}>
                    <Typography gutterBottom variant="h4" align="center">
                      Solutions Architect | Vodafone Group
                    </Typography>
                    <ExpAvatar 
                      alt="complex" 
                      src={Vodafone_logo} 
                    />
                    <ExperienceSection 
                      title="Start Application and Website"
                      time="July 2017 - December 2019"
                      description={"Collaborated with commercial owners to investigate migrating the existing app user base of 23 million into a revenue stream, I provided technical analysis and managed a user focus group resulting in the decision to sunset the application.\nLead architect on the iOS app and maximized reused and delivery speed by wrapping the existing browser implementation within a webview to give a native iOS experience."}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item key="mN" xs={12}>
              <Paper sx={{
                padding: theme.spacing(2),
                margin: 'auto'
              }}>
                <Grid container spacing={2}>
                  <Grid item key="Northrop" xs={12}>
                    <Typography gutterBottom variant="h4" align="center">
                      Software Engineer - DV cleared | Northrop Grumman
                    </Typography>
                    <ExpAvatar 
                      alt="complex" 
                      src={Northrop_logo} 
                    />
                    <ExperienceSection 
                      title="Cyber"
                      time="May 2016 - May 2017"
                      description={"Formalized the high-level design for the messaging system used in the successful bid for Nato’s next-gen maritime command and control system through collaboration with partners.\nTook on the role of Scrum master within the team and established the team's velocity."}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item key="mBT" xs={12}>
              <Paper sx={{
                padding: theme.spacing(2),
                margin: 'auto'
              }}>
                <Grid container spacing={2}>
                  <Grid item key="BT" xs={12}>
                    <Typography gutterBottom variant="h4" align="center">
                      Software Designer/Engineer | BT
                    </Typography>
                    <ExpAvatar 
                      alt="complex" 
                      src={BT_logo} 
                    />
                    <ExperienceSection 
                      title="Central Orchestration Platform"
                      time="June 2015 - May 2016"
                      description={"Reduced time to resolve failed orders on the orchestration platform by ~70% through pitching and developing a frontend that displayed key metrics in real-time using AngularJS.\nIntroduced new graduates to Agile working by co-hosting an onboarding Bootcamp."}
                    />
                    <ExperienceSection 
                      title="BT Wholesale: Architecture"
                      time="September 2013 - May 2015"
                      description={"At the University of Southampton, I co-ran a mock assessment center and a Q&A session leading to several applicants applying for the BT graduate scheme."}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
}