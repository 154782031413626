import React from 'react';
import { 
	Grid,
	Typography
} from '@mui/material';

export default function ExperienceSection(props) {
	
	return (
    <Grid container>
      <Grid item key="title" xs={12}>
        <Typography variant="h5">
         {props.title}
        </Typography>
      </Grid>
      <Grid item key="subtitle" xs={12}>
        <Typography variant="subtitle1">
         {props.time}
        </Typography>
      </Grid>
      <Grid item key="text" xs={12}>
        <Typography>
          <ul>
            {props.description.split("\n").map((i, key) => {
              if (i.startsWith("\t")) {
                return <ul><li key={key}>{i}</li></ul>;
              } else 
                return <li key={key}>{i}</li>;
              }
            )}
          </ul>
        </Typography>
      </Grid>
    </Grid>
	);
}
