import React from 'react';
import { 
  createTheme, 
  responsiveFontSizes, 
  ThemeProvider 
} from '@mui/material/styles';

import FrontPage from './sections/FrontPage.js';
import NavBar from './sections/NavBar.js';
import About from './sections/About.js';
import Experience from './sections/Experience.js';
import Parallax from './sections/Parallax.js';
import Footer from './sections/Footer.js';

import ThailandImage from './images/Thailand.webp';
import DrinksImage from './images/Drinks.webp';

function App() {
  let theme = createTheme({
    typography: {
      fontFamily: [
        'Rubik',
      ].join(','),
    },
  });
  theme = responsiveFontSizes(theme);
  
  return (
    <ThemeProvider theme={theme}>
      <NavBar/>
			<FrontPage/>
			<About/>
			<Parallax image={ThailandImage}/>
			<Experience/>
			<Parallax image={DrinksImage}/>
			<Footer/>
    </ThemeProvider>
  );
}

export default App;
