import React from 'react';
import { 
  createTheme
} from '@mui/material/styles';

import {
  Container,
	Typography,
  Paper,
  Grid,
  IconButton,
  Avatar,
  Tooltip
} from '@mui/material';

import Me from '../images/Me.webp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

const theme = createTheme();

export default function About() {
  
  const personal = "Hi there, and welcome to my little corner of the web! I'm Matthew, an Englishman originally from the countryside, who spent a good ten years in the hustle and bustle of London before relocating to Bangkok with my wonderful wife and our two adorable sausage dogs. Though I’m loving life in Thailand, I must admit, finding a proper cuppa is still a bit of a challenge! Outside of work, my wife and I are passionate equestrians, sharing a love for riding whenever we can. We also have a deep appreciation for the underwater world, frequently exploring as certified divers. While we’ve found common ground in these hobbies, I’ve yet to convert her to my guilty pleasure of tabletop wargaming — my personal favorite being Warhammer 40k. This site is a bit of a side project for me, so feel free to poke around and get in touch if anything catches your eye!"
  const professional = "A seasoned Solutions Architect with over a decade of experience leading global projects that serve millions of users. I have a proven track record of working alongside C-level professionals and managing complex stakeholder environments. My approach is driven by a commitment to delivering customer satisfaction through elegant solutions. Building trust and fostering open communication are at the core of my work, allowing for a clear understanding of objectives. This enables me to apply my architectural expertise, leadership skills, and technical acumen to deliver value for customers and stakeholders alike. My strategic focus has resulted in the successful delivery of projects of all scales, from modest initiatives to flagship, multi-million-pound endeavors, often under challenging conditions and across diverse, geographically dispersed teams.";

  return (
    <div>
      <meta name="description" content="About section provides a summary of me both personally and professionally." />
      <Container 
        id="About"
        maxWidth="lg" 
        sx={{
          paddingTop: theme.spacing(2),
          paddingBottom: theme.spacing(2)
        }} 
      >
        <Typography gutterBottom variant="h3" align="center">
          About me
        </Typography>
        <Paper sx={{
          padding: theme.spacing(2),
          margin: 'auto'
        }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4}>
              <Typography variant="h4">
                Personal
              </Typography>
              <Typography variant="body1">
                {personal.split("\n").map((i, key) => {
                  return <p key={key}>{i}</p>;
                })}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Avatar 
                sx={{
                  width: '100%',
                  height: '100%'
                }} 
                alt="complex" 
                src={Me} 
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <Typography variant="h4">
                Professional
              </Typography>
              <Typography variant="body1">
                {professional.split("\n").map((i, key) => {
                  return <p key={key}>{i}</p>;
                })}
              </Typography>
              <Grid container sx={{
                justifyContent: 'center'
              }}>
                <Tooltip title="LinkedIn">
                  <IconButton 
                    color="primary"
                    href="https://www.linkedin.com/in/matthew-white-ba30a95a/" 
                    target="_blank" 
                    rel="noreferrer"
                    aria-label="LinkedIn"
                  >
                    <LinkedInIcon/>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Credly">
                  <IconButton 
                    color="primary"
                    href="https://www.credly.com/users/matthew-white.1230beac/badges#" 
                    target="_blank" 
                    rel="noreferrer"
                    aria-label="Credly"
                  >
                    <WorkspacePremiumIcon/>
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Paper> 
      </Container>
    </div>
  );
}
