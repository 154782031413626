import React from 'react';
import {
  Box
} from '@mui/material';
import { Parallax } from 'react-parallax';

export default function ParallaxBreak(props) {
  return (
    <Box>
      <Parallax
        bgImage={props.image}
        strength={500}
      >
        <Box sx={{height: '50vh'}} />
      </Parallax>
    </Box>
  );
}