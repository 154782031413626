import React from 'react';
import { 
  createTheme
} from '@mui/material/styles';
import { styled } from '@mui/system';
import { 
  Container,
  AppBar,
  Toolbar,
  Avatar,
  Button,
  IconButton,
  Typography,
  List,
  ListItem,
  ListItemText,
  SwipeableDrawer,
  Divider,
  Tooltip
} from '@mui/material';

import WhatshotIcon from '@mui/icons-material/Whatshot';
import MenuIcon from '@mui/icons-material/Menu';
import Me from '../images/Me.webp';

const theme = createTheme();

const NavButton = styled(Button)({
  textTransform: "none",
  color: '#FFFFFF'
});

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

export default function NavBar() {

  function nav(nav) {
		window.location.href = nav;
  }
  
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  
  const mobile = (
    <Container 
      maxWidth="lg" 
      disableGutters={true} 
      sx={{
        display: { sm: 'none', xs: 'block' } 
      }}
    >
      <Toolbar>
        <div 
          sx={{
            position: 'absolute',
            right: '0%',
            paddingRight: theme.spacing(2)
          }}
        >
          <Tooltip title="Open drawer">
            <IconButton
              aria-label="Open drawer"
              edge="start"
              onClick={toggleDrawer('left', true)}
              sx={{
                color: '#FFFFFF'
              }}
            >
              <MenuIcon />
            </IconButton>
          </Tooltip>
          <SwipeableDrawer
            open={state['left']}
            onClose={toggleDrawer('left', false)}
            onOpen={toggleDrawer('left', true)}
          >
            <div
              role="presentation"
              onClick={toggleDrawer('left', false)}
              onKeyDown={toggleDrawer('left', false)}
            >
              <Container sx={{
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(2),
                width: theme.spacing(30),
              }}>
                <Avatar sx={{
                  margin: "auto",
                  width: theme.spacing(16),
                  height: theme.spacing(16)
                }} src={Me} />
              </Container>
              <Divider />
              <List>
                <ListItemLink href="#">
                  <ListItemText aria-label="Home" primary="Home" />
                </ListItemLink>
                <ListItemLink href="#About">
                  <ListItemText aria-label="About me" primary="About me" />
                </ListItemLink>
                <ListItemLink href="#Experience">
                  <ListItemText aria-label="Experience" primary="Experience" />
                </ListItemLink>
              </List>
            </div>
          </SwipeableDrawer>
        </div>
        <Typography 
          variant="h5"
          sx={{paddingLeft: theme.spacing(1)}}
        >
          MPWhite.co.uk
        </Typography>
      </Toolbar>
    </Container>
  );
  
  const desktop = (
    <Container 
      maxWidth="lg"
      sx={{ 
        display: { xs: 'none', sm: 'block' } 
      }}
    >
      <Toolbar>
        <Tooltip title="Home">
          <IconButton 
            edge="start"
            color="inherit"
            onClick={() => {nav("/#");}}
            aria-label="Home"
          >
            <WhatshotIcon />
          </IconButton>
        </Tooltip>
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          MPWhite.co.uk
        </Typography>
        <NavButton size="large" aria-label="Home" href="/#">Home</NavButton>
        <NavButton size="large" aria-label="About me" href="/#About">About me</NavButton>
        <NavButton size="large" aria-label="Experience" href="/#Experience">Experience</NavButton>
      </Toolbar>
    </Container>
  );
	
  return (
    <div>
      <meta name="description" content="Navigation bar to assist moving between sections." />
      <AppBar position="fixed" sx={{background: '#3f51b5'}}>
        {mobile}
        {desktop}
      </AppBar>
    </div>
  );
}